.ConnectCardContainer {
  // width: 96%;
  height: 94%;
  margin: auto;
  font-family: 'Helvetica Neue';
  font-style: normal;
  .IframeContainer {
    height: 100%;
    iframe {
      height: 100%;
      width: 100%;
    }
  }
  .Title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #182537;
  }
  .OptionBankContainer {
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(168, 168, 168, 0.25);
    border-radius: 5px;
    height: 189px;
    text-align: center;
    padding-top: 4%;
    margin-bottom: 24px;
  }
  .ButtonContainer {
    background: #0088ff;
    border-radius: 3px;
    cursor: pointer;
    height: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    padding-top: 11px;
    width: 100%;
  }
  .ButtonCustom {
    background-color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #0088ff;
    border: 1px solid #0088ff;
  }
  .BankNameContainer {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #182537;
    margin-bottom: 24px;
    margin-top: 9px;
  }
  .ant-row {
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f4f4f4;
    }
    &::-webkit-scrollbar {
      width: 4px;
      background-color: #f4f4f4;
      border: none;
    }

    &::-webkit-scrollbar-thumb {
      background: #d7dadd;
      border-radius: 6px;
    }
  }
}
/// connect card
.ConnectCardModal {
  .SubTitle {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #212b35;
    margin-top: 15px;
    margin-bottom: 24px;
  }
  .Title {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #182537;
    margin-top: 15px;
    margin-bottom: 17px;
    width: 80%;
    margin: auto;
    padding-top: 25px;
    padding-bottom: 16px;
  }
  .Require {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #212b35;
    margin-bottom: 4%;
    margin-top: 2%;
  }
  button:focus {
    outline: none;
  }
  .ant-modal-body {
    margin-top: 5%;
    width: 80%;
    margin: auto;
  }
  .BodyModal {
    margin-top: 5%;
    .TermConditionContainer {
      font-family: 'Helvetica Neue';
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #747c87;
    }
    .ButtonContainer {
      background: #0088ff;
      border-radius: 3px;
      height: 160px;
      height: 40px;
      padding-top: 2%;
      text-align: center;
      margin-top: 4%;
      cursor: pointer;
    }
    .NotifyRegisterContainer {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #747c87;
      margin-top: 4%;
    }
    .WaitToConnectContainer {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #182537;
      margin-top: 5%;
    }
    .ConnectBankSuccessTitle {
      text-align: center;
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #182537;
    }
    .ConnectBankSuccessInfo {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #182537;
    }
  }
}
/// register card
.RegisterCardModal {
  font-family: 'Helvetica Neue';
  font-style: normal;
  button:focus {
    outline: none;
  }
  // img{
  //     width: 187px;
  //     height: 180px;
  // }
  .BannerBankContainer {
    background-image: url('./../components/bankhup/bank-baner.png');
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #ffffff;
    font-size: 14px;
    line-height: 16px;
    .PromotionContainer {
      font-weight: 500;
      font-size: 26px;
      text-align: center;
      line-height: 32px;
      padding-bottom: 30px;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
  .RegisterCardContent {
    width: 95%;
    margin: auto;
    text-align: center;
    .TitleRegister {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #182537;
    }
    .SubTitle {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: center;

      color: #212b35;
    }
  }
  .TermConditionContainer {
    font-family: 'Helvetica Neue';
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #747c87;
  }
  .ButtonContainer {
    background: #0088ff;
    border-radius: 3px;
    cursor: pointer;
    height: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    padding-top: 3%;
    text-align: center;
  }
  .BodyModal {
    .ConnectBankSuccessTitle {
      text-align: center;
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #182537;
    }
    .ConnectBankSuccessInfo {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #182537;
    }
  }
}
