.HeaderBankHupContainer {
  height: 64px;
  background-color: #ffffff;
  & .TitleBankHupContainer {
    padding-top: 32px;
    height: 23px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #182537;
    margin-left: 3%;
  }
}
.UppercaseContainer {
  text-transform: uppercase;
}
.SpinContainer {
  text-align: center;
  .ant-spin-spinning {
    margin-top: 20%;
  }
  div {
    color: #0088ff !important;
  }
}
.SpinContainerCustom {
  .ant-spin-spinning {
    margin-top: 0%;
  }
}
.ButtonHelperContainer {
  & .ant-dropdown-menu-item > a:hover {
    background-color: red !important;
  }
}
.NotifyContainer {
  cursor: pointer;
}

.BodyBankHubContainer {
  background-color: #f4f4f4;
  height: 100%;
  div,
  span {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    // line-height: 24px;
    // font-size: 18px;
  }
  .ant-breadcrumb {
    padding-top: 8px;
    margin-bottom: 8px;
    line-height: 24px;
    font-size: 18px;
  }
  .CustomeBreadcrumbContainer {
    span {
      // font-size: 18px;
      // line-height: 24px;
      color: #747c87 !important;
      cursor: pointer;
    }
  }
  .ActiveLinkContainer {
    span {
      color: #182537 !important;
      cursor: default;
      font-weight: bold;
    }
  }
  .BreadcrumbIconCustom {
    svg {
      margin-bottom: 6px;
    }
  }

  & .LogoIntroduceContainer {
    // background-image: url('./../components/banner-bankhub-intro.png');
    width: 100%;
    height: 100%;
    // overflow: hidden;
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;
    position: relative;
    .ButtonRegisterContainer {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 30px;
    }
    & div {
      text-align: center;
      & img {
        width: 90%;
      }
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      // margin-top: 26px;
    }
    .GatewayInfo {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 32px;
      text-align: center;
      color: #182537;
    }
    .InforTitle {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #182537;
    }
    .CheckedInfoContainer {
      // width: 80%;
      // margin-left: 20%;
      margin-top: 0px;
      .Content {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #0088ff;
        text-align: start;
        width: 100%;
      }
      .Cheked {
        margin-top: 6%;
      }
    }
    // img{
    //     width: 100%;
    // }
  }
  & .InforFeatureContainer {
    text-align: center !important;
    border: 1px solid #ffffff;
    width: 100%;
    height: 200px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    & div:first-child {
      margin-top: 13.5%;
    }
  }
  & .InforTitleFeatureContainer {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #182537;
    margin-top: 2%;
  }
  & .ContentFeatureContainer {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #747c87;
    margin-top: 2%;
  }
  & .ButtonIntroduceContainer {
    width: 170px;
    height: 48px;
    background: #0088ff;
    border-radius: 3px;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }
  .CutomeButtonBankHub {
    background-color: #ffffff;
    color: #0088ff;
  }
  .ActiveBankHub {
    .InforFeatureContainer {
      cursor: pointer;
    }
  }
  .BankHubHome {
    .TextDetail {
      font-size: 14px;
      line-height: 17px;
      color: #0088ff;
      cursor: pointer;
    }
    .UserInfoContainer {
      background-color: #ffffff;
      border-radius: 5px;
      // height: 109px;
      display: flex;

      .UserInfoText {
        height: 48px;
        .HelloUser {
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          span {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
          }
        }
        .AccessManageText {
          margin-top: 2px;
          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #0088ff;
            cursor: pointer;
          }
        }
      }
      .IconFunction {
        text-align: center;
        img {
          height: 36px;
          cursor: pointer;
        }
        .SubTitle {
          margin-top: 8px;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #0088ff;
        }
        & span:first-child {
          margin-right: 24px;
        }
      }
      .BackGroundAvatar {
        width: 70px;
        height: 70px;
        background-color: rgba(0, 136, 255, 0.5);
        border-radius: 50%;
        justify-content: center;
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: auto;
        .StartNameLetter {
          color: white;
          font-weight: 500;
          font-size: 48px;
          line-height: 59px;
          text-transform: uppercase;
        }
      }
    }
    .TransactionTransferHome {
      height: 147px;
      .Title {
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }
      .Currency {
        font-weight: bold;
        font-size: 36px;
        line-height: 24px;
        text-align: center;
        color: #0088ff;
        text-transform: uppercase;
        margin-top: 29px;
      }
      .Header {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
      }
      span {
        img {
          cursor: pointer;
        }
      }
    }
    .AccountCardHome {
      // height: 147px;
      span {
        img {
          cursor: pointer;
        }
      }
      .Header {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
      }
      .ConnectCardText {
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        line-height: 19px;
        color: #0088ff;
        margin-top: 16px;
        padding-bottom: 16px;
      }
      .AccountCardItem {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        height: 38px;
        padding-top: 5px;
      }
    }
    .TransactionHistoryHome {
      background-color: #ffffff;
      height: 100%;
      border-radius: 5px;
      span {
        img {
          cursor: pointer;
        }
      }
      .EmptyHistory {
        min-height: 459px;
        height: 100%;
      }
      .Header {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
      }
      .TransactionItem {
        height: 61px;
        background-color: rgba(196, 196, 196, 0.2);
        border-radius: 5px;
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        div {
          font-size: 14px;
          color: #000000;
          line-height: 17px;
        }
      }
    }
  }
}

.ItemHoverContainer {
  & .ant-dropdown-menu-item > a:hover {
    background-color: #0088ff !important;
    color: #ffffff;
  }
}

.FormContainer {
  .ant-select-selection-item {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #182537;
  }
  //custome position icon range-picker
  .ant-picker-range {
    .anticon-calendar {
      padding-right: 12px;
    }
  }
  & .DataFormContainer {
    display: block;
    flex-direction: column;
  }
  .ant-input-number-focused {
    -webkit-box-shadow: none !important;
    box-shadow: none;
  }
  & input,
  textarea {
    width: 100%;
    height: 40px;
    padding-left: 13px;
    border: 1px solid #d3d5d7;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: #ffffff;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #182537;
    font-family: 'Helvetica Neue';
    &::placeholder {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #a3a8af;
      text-transform: none;
    }
    transition: 0.2s;
  }
  textarea {
    resize: none;
    padding-top: 9px;
    height: 80px !important;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #ffffff;
    }
    &::-webkit-scrollbar {
      width: 4px;
      background-color: #ffffff;
      border: none;
    }

    &::-webkit-scrollbar-thumb {
      background: #d7dadd;
      border-radius: 6px;
    }
  }

  .SuffixInputContainer {
    padding-right: 43px;
  }
  & .InputContainer {
    width: 100%;
    height: 40px;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .ant-input-number-handler-wrap {
      display: none;
    }
    .ant-input-number-input {
      padding-right: 43px;
    }
    .ant-input-number {
      border: none;
    }
    input:focus {
      border: 1px solid #1890ff !important;
      outline-offset: 0px !important;
      outline: none !important;
    }
    &:focus {
      border: 1px solid #1890ff !important;
      outline-offset: 0px !important;
      outline: none !important;
    }
  }

  & .LabelContainer {
    width: 100%;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #747c87;
    & span {
      color: red;
    }
    margin-bottom: 12px;
  }
  .LabelCustom {
    font-size: 14px;
  }
  .LabelCustomPadding {
    font-size: 14px;
    padding-top: 13px;
  }

  & .DropDownContainer {
    & .ant-select-selector {
      height: 40px;
      padding-top: 7px;
    }
    & .ant-select-selection-item {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #182537;
      padding-top: 5%;
    }
    & .ant-select {
      width: 100%;
    }
    .ant-select-selection-placeholder {
      margin-top: 10px;
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #a3a8af;
    }
  }
  & .DatePickerContainer {
    width: 100%;
    height: 40px;
    padding: 0;
    & .ant-picker-input {
      display: flex;
      flex-direction: row;
      & input {
        border: none;
        height: 36px;
      }
      & span {
        padding-right: 4%;
      }
    }
  }
  // select search
  .ant-select {
    width: 100% !important;
    .ant-select-selector {
      height: 40px;
    }
    .ant-select-selection-search-input {
      height: 100% !important;
    }
  }
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-picker-focused,
  .ant-select-open .ant-select-selector {
    border-color: #1890ff !important;
    box-shadow: none !important;
  }
  .ant-select-selector {
    .ant-select-selection-placeholder {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #a3a8af;
      padding-top: 11px;
    }
  }
  .-webkit-input-placeholder {
    color: blue;
  }
  .ant-select-selection-item {
    padding-top: 4px !important;
  }
  .ValidInput {
    border: 1px solid red !important;
  }
  .ant-select-selector {
    border-radius: 3px !important;
  }
  .ValidInputSelect {
    .ant-select-selector {
      border: 1px solid red !important;
      border-radius: 3px;
      transition: none;
    }
  }
  input:hover {
    border: 1px solid #1890ff;
  }
  .ModeViewContainer {
    margin-bottom: 0px;
  }
  .DataViewContainer {
    font-weight: bold;
  }
  .DataViewCustom {
    padding-top: 2px;
    padding-left: 13px;
  }
  .CustomSuffix {
    top: 8px;
    font-size: 14px;
    line-height: 17px;
    color: #a3a8af;
  }
}

.RegisterBankHubContainer {
  margin-top: 2%;
  .ant-spin-spinning {
    text-align: center;
    width: 100%;
    height: 403px;
    padding-top: 17%;
  }
  // min-height: 595px;
  // margin-left: 1%;
  // background-color:  #E5E5E5;
  // .ant-spin-spinning{
  //     text-align: center;
  //     width: 100%;
  //     height: 403px;
  //     padding-top: 17%;
  // }
  .HeaderRegisterBankContainer {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #182537;
  }
  .ButtonIcon {
    svg {
      margin-bottom: 7px;
    }
  }
  .TermConditionContainer {
    font-family: 'Helvetica Neue';
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #747c87;
  }
}
// overider antd
.rc-virtual-list-holder-inner {
  // overflow-y: scroll !important;
  // height: 400px !important;
}
.ant-picker-panel {
  width: 100%;
}
.ant-picker-footer {
  display: none;
}
.ant-modal-body {
  padding-top: 0px;
}
.ant-col {
  width: 100%;
}
// end overider antd
.HeaderRegisterModal {
  text-align: center;
  & div {
    width: 270px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  & p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #212b35;
  }
  & p:first-child {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #000000;
    margin-bottom: 0px;
  }
  .MessageTitleRegister {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #212b35;
    width: 100%;
  }
  .TitleModalContainer {
    width: 100%;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #182537;
  }
}
.ant-steps-item-content {
  // position: absolute !important;
  // bottom: 12px !important;
}
.StepContainer {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #747c87;
}
.StepNowContainer {
  border-bottom: 3px solid #007bff;
  padding-bottom: 1%;
}
.StepsContainer {
  width: 50%;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 2%;
  .ant-steps-item-title {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #182537;
  }
  .ant-steps-icon {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #d3d5d7;
  }
  .ant-steps-item-content {
    width: 100%;
    text-align: inherit;
  }
  .StepFinishContainer {
    .ant-steps-item-content {
      text-align: center;
    }
  }
  ////////////
  .ant-steps-finish-icon {
    height: 100%;
    svg {
      height: 100%;
      padding-bottom: 6px;
    }
  }
  .ant-steps-item-active {
    .ant-steps-item-title {
      color: #182537 !important;
    }
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    height: 2px;
    background-color: #0088ff;
  }
  .ant-steps-item-tail::after {
    height: 2px !important;
  }
}
.ant-modal-header {
  padding-bottom: 0;
}

//ant mess
.ant-message .anticon {
  top: -2px;
}
//===for button
.DisableButtonContainer {
  opacity: 50%;
  cursor: default !important;
}
.ButtonContainer {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  width: 160px;
  height: 48px;
  color: '0088FF';
  // svg{
  //     margin-bottom: 21%;
  // }
}
.ValidateInput {
  color: #ff4d4d !important;
  width: 100%;
  font-size: 12px !important;
  height: 15px !important;
  margin-top: 2px;
  font-family: 'Helvetica Neue';
}
.PackageActive {
  min-height: 334px !important;
  margin-top: 19px;
  :hover {
    cursor: pointer;
  }
  // margin-top: 25px;
}
.PackageNotActive {
  min-height: 352px !important;
  // margin-top: 19px;
}
.SelectPackage {
  border: 1px solid #0088ff !important;
  box-sizing: border-box;
}
.PackageDetailContainer {
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(2, 29, 52, 0.1);
  border-radius: 8px;
  text-align: center;
  // min-height: 334px;
  border: 1px solid transparent;
  margin-left: 10%;

  .PackageActiveContainer {
    background: #0088ff;
    border-radius: 8px 8px 0px 0px;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #ffffff;
    height: 25px;
    padding-top: 2%;
  }
  .PackageNotActiveContainer {
    border-radius: 8px 8px 0px 0px;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #ffffff;
    // height: 25px;
    padding-top: 2%;
  }
  .TitlePackageContainer {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #182537;
    margin-top: 1%;
  }
  .TitleInfoPackageContainer {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #747c87;
    margin-top: 1%;
  }
  .MonthPackageContainer {
    background-color: #e6f4ff;
    border-radius: 50px;
    width: 117px;
    height: 24px;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #33a0ff;
    padding-top: 1%;
    margin: auto;
    margin-top: 1%;
  }
  .CostPackageContainer {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    text-transform: uppercase;
    color: #0088ff;
    margin-top: 10px;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #0088ff;
  }
  .CheckedContainer {
    text-align: start;
    margin-bottom: 3%;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #46515f;
    padding-left: 2%;
    display: flex;
    div:first-child {
      margin-right: 3%;
    }
    img {
      padding-bottom: 1%;
    }
    // span{
    //     font-family: 'Helvetica Neue';
    //     font-style: normal;
    //     font-weight: normal;
    //     font-size: 12px;
    //     line-height: 14px;
    //     color: #46515F;
    //     padding-left: 2%;
    // }
  }
  .ContentCheckedContainer {
    display: flex;
    div {
      width: 100%;
    }
  }
  .ButtonPackageContainer {
    margin-top: 10%;
    margin-bottom: 7%;
    button {
      width: 124px;
      height: 34px;
      background: #ffffff;
      border: 1px solid #0088ff;
      box-sizing: border-box;
      border-radius: 15px;
      color: #0088ff;
    }
  }
  .DisableButtonPackage {
    button {
      background-color: #e5e5e5;
      cursor: default;
    }
  }
}
.RegisterBankSucContainer {
  text-align: center;
  margin-top: 5%;
  & div:first-child {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #182537;
    margin-bottom: 2%;
  }
  img {
    padding-right: 2%;
  }
  .MessRegisterSuccess {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #182537;
  }
}

.DropdownNotifyContainer {
  border-right: none;
  div {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  }
  .NotifyTitleContainer {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #212b35;
    padding-left: 7%;
  }
  .NotifyContentDetailContainer {
    padding-top: 6%;
    div {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #747c87;
    }

    .Notify {
      font-size: 14px;
      line-height: 17px;
      color: #182537;
      font-weight: bold;
    }
  }
  img {
    padding-top: 12%;
  }
  .LineDetailContainer {
    border-top: 1px solid #e8eaeb;
  }
  .ReadNotifyAllContainer {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #182537;
    text-align: center;
    border-top: 1px solid #e8eaeb;
    padding-top: 6px;
    cursor: pointer;
    height: 33px;
    &:hover {
      background-color: #f2f9ff;
      // background-color: red;
    }
  }
}
.ItemHoverContentContainer {
  &:hover {
    background-color: #f2f9ff;
  }
  height: 112px;
}
//header
.NotifyDropdownContainer {
}
// Notify transaction
.ant-popover-inner-content {
  padding: 0;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow-y: hidden;
  height: 480px;
  // &::-webkit-scrollbar-track{
  //     border-radius: 10px;
  //     background-color: #FFFFFF;
  // }
  // &::-webkit-scrollbar {
  //     width: 4px;
  //     background-color: #FFFFFF;
  //     border: none;
  // }

  // &::-webkit-scrollbar-thumb {
  //     background: #D7DADD;
  //     border-radius: 6px;
  // }
}

//====TransactionDetailContainer
.TransactionDetailContainer {
  background: #ffffff;
  border: 1px solid #e8eaeb;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 90%;
  margin: auto;
  margin-top: 3%;
  .Title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #212b35;
  }
  .Content {
    div {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #212b35;
      margin-top: 1%;
    }
  }
}

.TransactionHistoryComponent {
  margin: auto;
  // width: 94%;
  // margin-top: 1%;
  div,
  span,
  p {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
  }
  // page custome antd
  .ant-pagination-item {
    margin-top: 5px !important;
    padding-top: 1px;
  }
  .ant-pagination-item-active {
    color: white;
    background-color: #0088ff;
    border: none;
    border-radius: 50%;
    a {
      color: #ffffff;
    }
  }
  .ant-pagination-options {
    display: none;
  }
  .ant-select-arrow {
    svg {
      width: 10px;
      height: 12px;
    }
  }
  .ant-pagination-disabled {
    opacity: 50%;
    &:hover {
      cursor: default;
    }
  }
  .ant-pagination-item-link-icon {
    padding-top: 6px;
  }
  .ant-pagination-item-ellipsis {
    padding-top: 8px;
  }
  // end page custome antd
  .AmountStatusContainer {
    color: red !important;
  }
  .TableTransactionHisContainer {
    div,
    span,
    p {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #182537;
    }
    .CustomeIconTable {
      width: 3%;
    }
    .DataTransactionHistoryContainer {
      td,
      th {
        font-family: 'Helvetica Neue';
        font-style: normal;
        padding-left: 0px;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #182537;
      }
      th {
        font-weight: bold;
      }
      .ant-table-thead {
        .IdTableContainer {
          padding-left: 18px;
        }
      }
      .ant-table-body {
        height: 473px;
        // table{
        //     height: 100%;
        // }
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: #ffffff;
        }
        &::-webkit-scrollbar {
          width: 4px;
          background-color: #ffffff;
          border: none;
        }

        &::-webkit-scrollbar-thumb {
          background: #d7dadd;
          border-radius: 6px;
        }
      }
    }
  }

  .ButtonContainerCustom {
    text-align: center;
    width: 100%;
    height: 39px;
    padding-top: 10px;
    cursor: pointer;
  }
  .SearchTransactionHistoryContainer {
    background: #ffffff;
    padding-bottom: 16px;
    padding-top: 16px;
    border-radius: 3px;
    .InforAccount {
      width: 98%;
      margin: auto;
      padding-left: 12px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #182537;
      padding-bottom: 1%;
    }
  }
}

.ant-table-tbody > tr > td {
  padding: 0;
  height: 47px;
}
// .ant-select-dropdown{
//     overflow-y: scroll;
//     height: 200px;
//     &::-webkit-scrollbar-track{
//         border-radius: 10px;
//         background-color: #FFFFFF;
//     }
//     &::-webkit-scrollbar {
//         width: 4px;
//         background-color: #FFFFFF;
//         border: none;
//     }

//     &::-webkit-scrollbar-thumb {
//         background: #D7DADD;
//         border-radius: 6px;
//     }
// }
// .DropdownPageContainer{
//     overflow-y: hidden;
//     height: 137px;
// }
.ButtonContainer {
  background-color: #0088ff;
  border-radius: 3px;
  width: 160px;
  height: 48px;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
.ButtonCustomContainer {
  background-color: #ffffff;
  color: #0088ff;
  border: 1px solid #0088ff;
}

.BankHubHomeContainer {
  width: 100%;
  height: 100%;
  margin-top: 16px;
  div,
  p {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #747c87;
  }

  .BannerHomeContainer {
    background-image: url('./../components/banner-home-bh.svg');
    width: 100%;
    height: 48%;
    border-radius: 10px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    .BannerHome {
      position: absolute;
      left: 20%;
      top: 50%;
      transform: translate(-10%, -50%);
    }
    .GatewayInfo {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: #182537;
      // margin-top: 12%;
    }
    .InforTitle {
      color: #46515f;
      // margin-top: 12%;
    }
    .LogoBankHubContainer {
      img {
        // margin-top: 12%;
        width: 183px;
      }
    }
  }
  .OptionServiceBHContainer {
    padding-top: 2%;
    margin: auto;
    .BankHubOptionsContainer {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      border-radius: 5px;
      min-height: 170px;
      justify-content: center;
      margin-bottom: 3%;
      font-family: 'Helvetica Neue';
      font-style: normal;
      .Title {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #182537;
      }
      .Description {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #747c87;
      }
      &:hover {
        border: 1px solid #99cfff;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }
    }
  }
}
.WaitActiveBHContainer {
  text-align: center;
  width: 45% !important;
  .ant-modal-body {
    padding-top: 8%;
    font-family: 'Helvetica Neue';
    font-style: normal;
    div {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #182537;
    }
    .Title {
      margin-bottom: 4%;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #182537;
    }
  }
  .ant-modal-content {
    height: 473px;
  }

  button:focus {
    outline: none;
  }
}
.ModalBankHubOptionContainer {
  button:focus {
    outline: none;
  }
  .svg {
    margin-bottom: 9px !important;
  }
  .ant-modal-body {
    min-height: 450px;
  }
}
.NotifyHeaderBankHubContainer {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  padding-top: 9px;
  height: 34px;
}

///////////////
.NotifyAmountContainer {
  margin-top: 1%;
  // padding-bottom: 2%;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #747c87;
  .ChangeAmountContainer {
    height: 120px;
    // padding-top: 2%;
    overflow-y: scroll;
    height: 720px;
    .Title {
      color: #182537;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #e8eaeb;
    }
    &::-webkit-scrollbar {
      width: 6px;
      background-color: #e8eaeb;
      border: none;
    }

    &::-webkit-scrollbar-thumb {
      background: #a3a8af;
      border-radius: 6px;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//Box component
.BoxContainer {
  background: #ffffff;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  div,
  p,
  a {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #182537;
  }
  .BoxChildrenContainer {
    width: 87%;
    height: 96%;
    margin: auto;
    .HeaderBox {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
      margin-top: 22px;
      margin-bottom: 11px;
    }
    .FooterBox {
      margin-bottom: 25px;
    }
    .DownloadBill {
      font-size: 12px;
      line-height: 14px;
      color: #747c87;
      margin-left: 25px;
    }
  }
}

.ButtonDefaultContainer {
  background-color: #0088ff;
  border-radius: 3px;
  height: 36px;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 17px !important;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  padding-top: 10px;
  width: 124px;
  // &:hover{
  //     opacity: 100%;
  // }
}
.ButtonDefaultExtraContainer {
  border: 1px solid #0088ff;
  color: #0088ff;
  background-color: #ffffff;
  margin-right: 16px;
}
// .ButtonLoading{
//     cursor: default;
//     opacity: 50%;
// }
.ButtonDisable {
  cursor: default;
  opacity: 0.5;
}
.ButtonOptionIcon {
  svg {
    margin-bottom: 8px;
  }
}

//modal manage account
.ActionManageContainer {
  div {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #182537;
  }
  .Title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 16px;
  }
  .Info {
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 24px;
    font-weight: bold;
  }
  .ant-modal-body {
    padding-top: 24px;
  }
  .CustomeClassCancel {
    color: #0088ff;
    width: 128px;
    height: 48px;
    font-size: 16px;
    padding-top: 14px;
  }
  .CustomeClassSubmit {
    color: #ffffff;
    width: 155px;
    font-size: 16px;
    padding-top: 14px;
    height: 48px;
  }
  padding-top: 14px;
  .CustomeClassCancelDelete {
    color: #ff4d4d;
    width: 128px;
    height: 48px;
    font-size: 16px;
    padding-top: 14px;
    border: 1px solid #ff4d4d;
  }
  .CustomeClassSubmitDelete {
    width: 155px;
    height: 48px;
    font-size: 16px;
    padding-top: 14px;
    background-color: #ff4d4d;
    color: #ffffff;
  }
}
.ModalSalaryContainer {
  div {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #182537;
  }
  .Title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 16px;
  }
  .Info {
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 24px;
  }
  .ant-modal-body {
    padding-top: 24px;
  }
  .CustomeClassCancel {
    color: #0088ff;
    width: 128px;
    height: 48px;
    font-size: 16px;
  }
  .CustomeClassSubmit {
    color: #ffffff;
    width: 155px;
    font-size: 16px;
    height: 48px;
  }
  .CustomeClassCancelDelete {
    color: #ff4d4d;
    width: 82px;
    height: 48px;
    font-size: 16px;
    border: 1px solid #ff4d4d;
    line-height: 24px !important;
  }
  .CustomeClassSubmitDelete {
    width: 79px;
    height: 48px;
    font-size: 16px;
    background-color: #ff4d4d;
    color: #ffffff;
    line-height: 24px !important;
  }
}
.TransferSalaryModal {
  div {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #182537;
  }
  .Title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  .Info {
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 24px;
    font-weight: 700;
  }
  .ant-modal-body {
    padding-top: 24px;
  }
  .CustomeClassCancelDelete {
    color: #ff4d4d;
    width: 82px;
    height: 48px;
    font-size: 16px;
    border: 1px solid #ff4d4d;
  }
  .CustomeClassSubmitDelete {
    width: 79px;
    height: 48px;
    font-size: 16px;
    background-color: #ff4d4d;
    color: #ffffff;
  }
}
// ==== notify bankhub ===== //
.NotifyBHContainer {
  div {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    color: #182537;
  }
  .ant-notification-notice-message {
    font-size: 17px;
    line-height: 24px;
  }
  .ant-notification-notice-description {
    font-size: 13px;
    line-height: 16px;
  }
}

// permission denied page ===========
.PermissionDeniedContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  height: 100%;
  background-color: #f4f4f4;
  img {
    width: 459px;
    height: 284px;
    object-fit: contain;
  }
  .Info {
    margin-top: 36px;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #182537;
  }
}
//modal confirm salary ========
.ConfirmSalaryContainer {
  div {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-size: 14px;
    color: #212b35;
    font-weight: normal;
  }
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-title {
    border-bottom: 1px solid #e8eaeb;
  }
  .TitleModal {
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
    .Title {
      margin-bottom: 16px;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #182537;
    }
    .Content {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #182537;
      width: 75%;
      margin: auto;
    }
  }
  .TitleBody {
    text-align: center;
    margin-top: 16px;
    & div:first-child {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #182537;
    }
    & div:last-child {
      margin-bottom: 24px;
      font-weight: bold;
      font-size: 38px;
      line-height: 46px;
      color: #0088ff;
    }
  }
  .Label {
    div {
      font-size: 14px;
      line-height: 17px;
      color: #747c87;
      height: 28px;
    }
  }
  .LabelValue {
    div {
      font-size: 14px;
      line-height: 17px;
      height: 28px;
      span {
        padding-right: 12px;
      }
    }
  }
  .ButtonSubmit {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px !important;
    color: #ffffff;
    width: 128px;
    height: 48px;
    // padding-top: 12px;
  }
  .ButtonCheck {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px !important;
    color: #0088ff;
    width: 128px;
    height: 48px;
    // padding-top: 12px;
  }
  .ant-modal-close {
    outline: none;
  }
}
.ConfirmOkSalaryContainer {
  .ant-modal-title {
    border-bottom: none;
  }
}
