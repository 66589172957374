.ManageAccountContainer{
    // margin-top: 1%;
    // width: 94%;
    margin: auto;
    color: #182537;
    div{
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: normal;
    }
    .InfoContainer{
        border-radius: 8px;
        margin-bottom: 24px;
        // margin-top: 1%;
        background-color: #FFFFFF;
        .BannerManageAccount{
            background-image: url('./../components/bankhup/banner-manage-account.svg');
            width: 100%;
            height: 100%;
            overflow: hidden;
            background-size: auto;
            background-position: center;
            background-repeat: no-repeat;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            align-items: center;
        }
    }
    .InfoAccountContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 75%;
        // border:1px solid red;
    }
    .InfoAccountLine{
        border-right: 1px solid #E8EAEB;
    }
    .InfoAccount{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
    .InfoAccountLineBorder{
        border-bottom: 1px solid #E8EAEB;
    }
    .LabelInfoAccount{
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #747C87;
    }
    .SumAmountInfo{
        font-size: 14px;
        line-height: 24px;
        color: #747C87;
    }
    .SumAmount{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 24px;
        color: #0088FF;
    }
    //account detail
    .AccountDetailContainer{
        // margin-top: 24px;
        .ant-row{
            &::-webkit-scrollbar-track{
                border-radius: 10px;
                background-color: #f4f4f4;
            }
            &::-webkit-scrollbar {
                width: 4px;
                background-color: #f4f4f4;
                border: none;
            }
            
            &::-webkit-scrollbar-thumb {
                background: #D7DADD;
                border-radius: 6px; 
            }
        }
      
        .AccountDetail{
            background-color: #FFFFFF;
            border-radius: 8px;
            // height: 255px;
            font-size: 12px;
            line-height: 14px;
            color: #747C87;
            .AccountInfoDetail{
                width: 95%;
                margin: auto;
                .DisplayInfo{   
                    font-weight: normal;
                    display: flex;
                    justify-content: space-between;
                    margin: auto;
                    width: 100%;
                }
                .NameInfo{
                    div{

                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                        color: #182537;
                    }
                }
                .AcountInfo{
                    div{
                        font-size: 16px;
                        line-height: 19px;
                        color: #182537;
                    }
                }
                
            }
        }
        .TagsContainer{
            width: 129px;
            height: 29px;
            font-family: 'Helvetica Neue';
            font-style: normal;
            font-weight: normal;
            font-size: 12px !important;
            line-height: 17px !important;
            color: #EE4747 !important;
            background-color : #FFF6F6;;
            border-radius: 40px;
            text-align: center;
            margin-top: 5px;
        }
        .Active{
            color: #0DB473 !important;
            background-color : #F3FCF9;
        }
        .ButtonActiveContainer{
            background-color: #FFFFFF;
            border: 1px solid #0088FF;
            box-sizing: border-box;
            border-radius: 3px;
            font-family: 'Helvetica Neue';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            width: 160px;
            height: 40px;
            color: #0088FF !important;
            text-align: center;
            padding-top: 8px;
            cursor: pointer;
        }
        .ButtonInActiveContainer{
            background-color: #0088FF;
            color: #FFFFFF !important;
        }

    }
}