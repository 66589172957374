.TransferSalaryContainer{
    // width: 94%;
    margin: auto;
    div,span{
        font-family: 'Helvetica Neue';
        font-style: normal;
    }
  
    .TransferSalaryActionContainer{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        // padding-top: 1%;
        text-align: center;
        & div:first-child{
            margin-right: 16px;
        }
        .ButtonTranferCustome{
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            min-width: 220px;
            height: 40px;
            padding-top: 10px;
            svg{
                margin-bottom: 6px;
            }
        }
    }
    .BodyTranferSalaryContainer{
        background-color: #FFFFFF;
        margin-top: 24px;
        .OptionFilterContainer{
            display: flex;
            border-bottom: 1px solid #D3D5D7;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            text-align: center;
            color: #747C87;
            height: 48px;
            div{
                height: 48px !important;
                width: 180px;
                height: 100%;
                padding-top: 16px;
                cursor: pointer;
                font-size: 14px;
            }
            .ActiveTitle{
                color: #0088FF;
                border-bottom: 2px solid #0088FF;
            }
         
        }
        .TableSalaryContainer{
            // table{
            //     height: 100%;
            // }
            .TableSalaryTransferContainer{
                // table{
                //     table-layout: initial;
                //     height: 100%;
                //  }
                .ant-table-body{
                    
                    height: 474px;
                    &::-webkit-scrollbar-track{
                        border-radius: 10px;
                        background-color: #FFFFFF;
                    }
                    &::-webkit-scrollbar {
                        width: 4px;
                        background-color: #FFFFFF;
                        border: none;
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        background: #D7DADD;
                        border-radius: 6px; 
                    }
                 }
                td,th {
                    font-family: 'Helvetica Neue';
                    font-style: normal;
                    padding-left: 0px;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 17px;
                    color: #182537;
                    // height: 52px;
                }
                th{
                    font-weight: bold;
                    // height: 58px;
                }
                .ant-table-header{
                    background-color: #F4F6F8;
                    border: 1px solid #D3D5D7;
                    border-bottom: none;
                }
                .ant-table-thead{
                    .CustomNumberOrder{
                        padding-left: 15px !important;
                    }
                }
               
            }
            .TagContainer{
                width: 88px;
                height: 24px;
                background-color: #FFF6F6;;
                color: #EE4747;
                border-radius: 40px;
                text-align: center;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 14px;
                font-family: 'Helvetica Neue';
                padding-top: 5px;
            }
            .SuccessStatus{
                background-color: #F3FCF9;;
                color: #0DB473;
            }
            .WaitStatus{
                background-color: #FFF9EB;
                color: #FFC95C;
            }
            
        }
    }
    .SearchTranferSalaryContainer{
        width: 97%;
        margin:auto;
        margin-top: 1%;
        margin-bottom: 1%;
        .ant-input-affix-wrapper{
            height: 36px;
        }
        .ant-input-prefix{
            opacity: 0.5;
        }
        .anticon-search{
            font-size: 17px;
        }
        .ButtonTransferContainer{
            background-color: #0088FF;
            border-radius: 3px;
            color: #FFFFFF;
            text-align: center;
            min-width: 124px;
            height: 36px;
            cursor: pointer;
            font-size: 14px;
            line-height: 17px;
            padding-top: 8px;
        }
    }
    .EmployeeTransferContainer{
        .EmployeeTableTransferContainer{
            .ant-table-body{
                height: 473px;
                &::-webkit-scrollbar-track{
                    border-radius: 10px;
                    background-color: #FFFFFF;
                }
                &::-webkit-scrollbar {
                    width: 4px;
                    background-color: #FFFFFF;
                    border: none;
                }
                
                &::-webkit-scrollbar-thumb {
                    background: #D7DADD;
                    border-radius: 6px; 
                }
             }
            td,th {
                font-family: 'Helvetica Neue';
                font-style: normal;
                padding-left: 0px;
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                color: #182537;
            }
            th{
                font-weight: bold;
            }
            .ant-table-header{
                background-color: #F4F6F8;
                border: 1px solid #D3D5D7;
                border-bottom: none;
            }
            .EmployeeTransferDetailContainer{
                width: 86%;
                margin: auto;
                margin-top: 26px;
                margin-bottom: 26px;
                .HeaderRegisterBankContainer{
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 20px;
                    color: #182537;
                }
                .ButtonEmployeeTransferDetail{
                    background-color: #0088FF;
                    border-radius: 3px;
                    width: 74px;
                    height: 36px;
                    font-family: 'Helvetica Neue';
                    font-style: normal;
                    font-weight: 500;
                    line-height: 19px;
                    color: #FFFFFF;
                    cursor: pointer;
                    text-align: center;
                    padding-top: 4px;
                    span{
                        font-family: 'Helvetica Neue';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 19px;
                        color: #FFFFFF;
                        cursor: pointer;
                        text-align: center;
                        padding-bottom: 1px;
                    }
                }
                .ButtonEmployeeTransferDetailCustom{
                        background-color: #FFFFFF;
                        border: 1px solid #0088FF;
                        margin-right: 16px;
                        span{
                            color: #0088FF;

                        }                  
                }
                .TransferDetailDisable{
                    cursor: default;
                    opacity: 50%;
                }
            }
            .ant-table-expanded-row{
                .ant-table-cell{
                    background-color: #F9FAFA;
                    border: 1px solid #D3D5D7;
                }
                
            }
        }
        .CustomNumberOrder{
            padding: inherit;
        }
    }
}
.TooltipCustomContainer{
    .ant-tooltip-inner{
        background-color: #FFFFFF;
        box-sizing: border-box;
        border-radius: 3px;
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }
}

// thiet lap chuyen luong
.TransferSalarySettingContainer{
    // width: 96%;
    margin: auto;
    // margin-top: 1%;
    border-radius: 3px;
    // .BoxCustomContainer{
    //     .BoxChildrenContainer{
    //         width: 96%;
    //     }
    // }
    .HistoryContainer{
        width: 100%;
        background-color: #ffffff;
        margin-top: 7px;
        div{
            font-family: 'Helvetica Neue';
            font-style: normal;
            font-weight: bold;
            color: #182537;
            font-size: 14px;
        }
        .Header{
            font-size: 16px;
            line-height: 20px;
            margin-left: 16px;
            height: 48px;
            padding-top: 12px;
        }
        .Info{
            height: 52px;
            margin-left: 16px;
            margin-top: 16px;
            div{
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                color: #212B35;
                // padding-top: 16px;
            }
           
        }
    }
    .ButtonDeleteContainer{
        background: #FFFFFF;
        border: 1px solid #EB3838;
        box-sizing: border-box;
        border-radius: 3px;
        color: #EB3838;
        margin-right: 16px;
    }
    .ant-row{
        width: 100%;
    }
    .SalaryStatusTag{
        width: 88px;
        height: 24px;
        background-color: #FFF6F6;;
        color: #EE4747;
        border-radius: 40px;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        font-family: 'Helvetica Neue';
        padding-top: 5px;
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .SuccessStatus{
        background-color: #F3FCF9;;
        color: #0DB473;
    }
    .WaitStatus{
        background-color: #FFF9EB;
        color: #FFC95C;
    }
    .SalaryNameContainer{
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #182537;
    }
    .SettingSalaryContainer{
        width: 96%;
       
       
    }
    .SearchEmplTranferSalaryContainer{
        width: 97%;
        margin:auto;
        margin-top: 1%;
        margin-bottom: 1%;
        .ant-input-affix-wrapper{
            height: 36px;
        }
        .ant-input-prefix{
            opacity: 0.5;
        }
        .anticon-search{
            font-size: 17px;
        }
    }
    .TransferSalarySetting{
        background-color: #FFFFFF;
        .Title{
            font-family: 'Helvetica Neue';
            width: 97%;
            margin: auto;
            font-weight: 500;
            font-size: 16px;
            line-height: 17px;
            display: flex;
            color: #0088FF;
            height: 48px;
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
        }
    }
    .EmployeeTransferSettingContainer{
        // table{
        //     height: 100%;
        //     table-layout: initial;
        // }
        .ant-table-expanded-row{
            .ant-table-cell{
                background-color: #F9FAFA;
                border: 1px solid #D3D5D7;
            }
            
        }
        .ant-table-body{
            height: 364px;
            &::-webkit-scrollbar-track{
                border-radius: 10px;
                background-color: #FFFFFF;
            }
            &::-webkit-scrollbar {
                width: 4px;
                background-color: #FFFFFF;
                border: none;
            }
            
            &::-webkit-scrollbar-thumb {
                background: #D7DADD;
                border-radius: 6px; 
            }
         }
        td,th {
            font-family: 'Helvetica Neue';
            font-style: normal;
            padding-left: 0px;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #182537;
        }
        th{
            font-weight: bold;
        }
        .ant-table-header{
            background-color: #F4F6F8;
            border: 1px solid #D3D5D7;
            border-bottom: none;
        }
        input{
            text-align: right;
            width: 100%;
            height: 40px;
            padding-right: 12px;
            border: 1px solid #D3D5D7;
            box-sizing: border-box;
            border-radius: 3px;
            background-color: #ffffff;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #182537;
            font-family: 'Helvetica Neue';
            &::placeholder {
             color: #A3A8AF;
           }
           transition: 0.2s;
        }
        input:focus{
            border : 1px solid #1890ff !important;
            outline-offset: 0px !important;
            outline: none !important;
        }
    }
    .CustomNumberOrder{
        padding: inherit;
    }
    .ButtonTransferSalary{
        .ButtonDefaultContainer {
            width: 124px;
        }
    }
    .SumarySalary{
        .Label{
            font-family: 'Helvetica Neue';
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: #182537;
        }
        .Value{
            font-family: 'Helvetica Neue';
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            color: #0088FF;
        }
    }
}