.CalculatorContainer {
  margin: 0 32px;
  div,
  span {
    font-family: 'Helvetica Neue';
    font-style: normal;
  }
  .Repayment {
    margin: auto;
    margin-left: 3%;
    margin-top: 15px;
  }
  // background-color: #e5e5e5;
  .ButtonShow {
    background-color: #0088ff;
    margin-top: 24px;
    border-radius: 3px;
    width: 146px;
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    padding-top: 9px;
    justify-content: center;
  }

  .calculator_menu {
    min-height: 400px;
    // margin: 0 32px;
    margin-top: 16px;
    background-color: #ffffff;
  }
  .active-container-calculator {
    border-bottom: 3px #007bff solid;
    color: #0088ff;
  }
  .title-calcualator {
    display: flex;
    width: 96%;
    margin: auto;
    height: 100%;
    & div {
      cursor: pointer;
      margin-right: 48px;
      padding-top: 10px;
      // margin: 10px 30px 10px 20px;
    }
    // & div:hover {
    //   color: #66b8ff;
    // }
    color: #747c87;
    font-weight: map-get($font-weight, 'bold');
    // border-bottom: 1px #e8eaeb solid;
  }

  .table-calculator {
    margin: 20px 30px;
  }
  .loan-limit {
    margin-left: -1%;
    margin-top: 23px;
  }
  .boder-left {
    border-left: 1px #e8eaeb solid;
  }

  .ButtonContainer {
    width: 160px;
    height: 40px;
    background: #0088ff;
    border-radius: 3px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    padding-top: 11px;
    text-align: center;
    // display: flex;
    // flex-direction: column-reverse;
    svg {
      padding-bottom: 2px;
    }
  }
  .font14 {
    font-size: 14px;
  }
  .font16 {
    font-size: 16px;
  }
  .product-back-icon-calculator {
    margin-right: 12px;
    margin-left: 12px;
  }
  .lst-repayment-tbl-container-calculator {
    height: 333px;
  }
}
