.landingPage {
  background-color: #f4f4f4;
  height: 100%;
  font-family: 'Helvetica Neue', sans-serif;
  
  .landingContainer {
    margin: 0 32px;
    .landingBanner {
      img {
        width: 100%;
      }
    }
    .landingOptionsContainer {
      .FinOptionsContainer {
        background-color: #ffffff;
        border: 1px solid transparent;
        border-radius: 5px;
        height: 170px;
        .FinOptionTitle {
          //   font-family: 'Helvetica Neue';
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          color: #182537;
          margin-top: 2%;
        }
        .FinOptionDescription {
          //   font-family: 'Helvetica Neue';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #747c87;
          width: 75%;
          margin: auto;
          margin-top: 2%;
        }
        .ImgFinOption {
          padding-top: 32px;
        }
        .FinPartnerTitle {
          //   font-family: 'Helvetica Neue';
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          color: #182537;
          margin-top: 8px;
        }
        .FinPartnerDescription {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: #747c87;
          margin-top: 8px !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .ImgFinPartner {
          padding-top: 16px;
        }
        .FinPartnerLoanInfo {
          height: 66px;
          padding: 12px 16px;
          gap: 14px;
          border-radius: 6px;
          border: 1px dotted #CCE7FF;
          width: 90%;
          margin-top: 16px !important;
          background-color: #F2F9FF;
          margin: auto;
          font-family: 'Roboto';
        }
        .FinPartnerLoanInfoTitle {
          font-size: 12px;
          font-weight: 400;
        }
        .FinPartnerLoanInfoContent {
          font-size: 16px;
          color: #0088ff;
        }
        .FinPartnerRegisterButton {
          margin: 16px 0px;
        }
      }
     
      .font-text {
        color: #46515f;
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
      }
      .btn-regis {
        padding: 12px 16px;

        width: 280px;
        height: 50px;

        /* Primary/100 */

        background: #0088ff;
        border-radius: 3px;
      }
    }
  }
  .landingFooterRow {
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
  }
  .landingFooter {
    background-color: #ebebeb;
    margin: 24px 0 12px;
    div {
      //   font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #747c87;
    }
    .SapoInFoCustom {
      padding-left: 8%;
      .landingSuport {
        display: flex;
        flex-direction: column;
        margin-left: 2%;
      }
    }
    .SapoInFoCustomSuport {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-content: center;
      .landingSuport {
        display: flex;
        flex-direction: column;
        // margin-left: 10%;
      }
    }
    .SapoInfor {
      padding-left: 8%;
      border-right: 1px solid #dddfe1;
      div {
        text-decoration: underline;
        span {
          cursor: pointer;
        }
      }
    }
  }
}
.hotline-phone-ring-wrap {
  position: fixed;
  bottom: 48px;
  right: 8px;
  z-index: 999999;
}
.hotline-message-ring-wrap {
  position: fixed;
  bottom: 60px;
  right: 0;
  z-index: 999999;
}

