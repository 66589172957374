.select-custom__root {
  display: flex;
  flex-direction: column;
  max-width: 230px;
  min-width: 170px;
  width: 100%;
  position: relative;

  &:hover {
    .select-custom__header{
      border: 1px solid #0088ff;
    }
  }

  @media (max-width: 1100px) {
    max-width: 200px;
  }
}

.select-custom__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 10px 12px;
  height: 36px;
  max-width: 230px;
  min-width: 170px;

  background: #ffffff;
  border: 1px solid #e8eaeb;
  box-sizing: border-box;
  border-radius: 0px;

  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    border: 1px solid #0088ff;
  }
  @media (max-width: 1100px) {
    max-width: 200px;
  }
}

.select-custom__header_title {
  font-size: 14px;
}

.select-custom__header_image {
  transition: transform 0.2s ease;
}

.select-custom__header_image_open {
  transform: rotate(180deg);
}

.select-custom__group {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  border: 1px solid #e8eaeb;
  background: white;
  top: 43px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;

  &:last-of-type {
    border-bottom: none;
  }

  &::before {
    content: ' ';
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-left: 1px solid #d3d5d7;
    border-top: 1px solid #d3d5d7;
    transform: rotate(45deg);
    z-index: 10;
    top: -6px;
    left: 85px;
  };

  transition: opacity 0.2s, visibility 0s;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
}

.open_group {
  opacity: 1 !important;
  visibility: visible !important;
}

.select-custom__option {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: 14px;

  .ant-checkbox-wrapper {
    width: 15%;
  }
  padding: 10px 12px;
  height: 36px;
  transition: 0.2s;
  border-bottom: 1px solid #e8eaeb;
}
