.status-fin {
  height: 24px;
  font-style: normal;
  font-weight: normal;
  border-radius: 20px;
  padding: 4px 16px;
  font-family: Roboto;
  font-size: 12px;
  line-height: 16px;
}

.tbl-application {
  .thead {
    border-bottom: 1px solid #e8eaeb !important;
  }
  th {
    vertical-align: middle !important;
    border-top: none !important;
    border-bottom: none !important;
    padding: 12px;
  }
  td {
    vertical-align: middle !important;
    border-bottom: none !important;
    border-top: none !important;
  }
  tr {
    border-bottom: 1px solid #e8eaeb !important;
    border-top: none !important;
  }
  .th-status {
    width: 12%;
  }
}

.span-link {
  color: #0088ff;
  cursor: pointer;
  font-style: normal;
  font-size: 16px;
}

.label-detail {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #747c87;
  max-width: 35%;
  flex-basis: 35%;
}
.value-detail {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  max-width: 75%;
  color: #000;
  max-width: 65%;
  flex-basis: 65%;
}
.Display-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.Display-direction-column {
  flex-direction: column;
}
.Display-item {
  margin: 0;
  box-sizing: border-box;
}
.Step-detail {
  .ant-steps-item-title {
    top: -60px !important;
  }
  .ant-steps-item-icon {
    border-radius: 24px !important;
    width: 24px !important;
    height: 24px !important;
  }
  .ant-steps-item-description {
    margin-top: -28px !important;
    color: #747c87 !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 10px;
    line-height: 16px;
  }
  .ant-steps-item-process {
    .ant-steps-item-tail::after {
      background-color: #d3d5d7 !important;
    }
  }
  .ant-steps-item-wait {
    .ant-steps-item-tail::after {
      background-color: #d3d5d7 !important;
    }
  }

  .ant-steps-item-icon {
    margin-left: 46px;
    background: #0088ff !important;
    .ant-steps-icon {
      color: #fff;
    }
  }
  .step-detail-reject {
    .ant-steps-item-icon {
      background: #971f0f !important;
      border: none;
    }
  }
  .anticon {
    vertical-align: middle;
    font-size: 14px;
    /* font-weight: bold; */
    color: #fff;
  }
  .befor-reject {
    .ant-steps-item-tail::after {
      background-color: #d3d5d7 !important;
    }
  }
  .ant-steps-item-wait {
    .ant-steps-item-icon {
      margin-left: 46px;
      background: #fff !important;
    }
    .ant-steps-icon {
      color: #d3d5d7;
    }
  }
  .ant-steps-item-wait {
    .ant-steps-item-icon {
      margin-left: 46px;
      background: #d3d5d7ff !important;
      border: none;
      .ant-steps-icon {
        display: none !important;
      }
    }
    .ant-steps-icon {
      color: #d3d5d7;
    }
  }
}
.tbl-application-container {
  .PaginationContainer {
    margin-right: 5px;
  }
  box-shadow: none;
}
.ModalPreview {
  .ant-modal-title {
    padding-bottom: 5px;
  }
  .ant-modal-close-x {
    line-height: 35px !important;
  }
}

.header-fixed {
  background: #fff;
  left: 0;
  z-index: 1001;
  position: fixed;
  padding: 15px 35px 15px 25px;
  top: 0;
  right: 0;
}
.page-heading {
  display: none !important;
}
.application-copy {
  .register-lead-form-input {
    width: 100% !important;
    height: 40px !important;
  }
}
.application-copy {
  .ant-form-item-required::before {
    display: none !important;
  }
  #register-lead-form_dob:focus {
    border-color: #40a9ff !important;
    border-right-width: 1px !important;
    outline: 0;
  }
  #register-lead-form_dob {
    border: 1px solid #d9d9d9 !important;
    padding-left: 10px !important;
  }
  #register-lead-form_icIssuedOn:focus {
    border-color: #40a9ff !important;
    border-right-width: 1px !important;
    outline: 0;
  }
  #register-lead-form_icIssuedOn {
    border: 1px solid #d9d9d9 !important;
    padding-left: 10px !important;
  }
  .react-datepicker__header {
    background: #fff !important;
    border-bottom: none;

    .react-datepicker__current-month {
      display: none !important;
    }
    .react-datepicker__month-dropdown-container--scroll {
      margin-right: 25px;
    }
    .react-datepicker__month-dropdown {
      background: #fff !important;
      text-align: left;
      padding: 10px;
      top: 38px !important;
    }
    .react-datepicker__year-dropdown {
      background: #fff !important;
    }
    .react-datepicker__navigation {
      margin-top: 8px !important;
    }
    .react-datepicker__year-dropdown-container--scroll {
      margin-right: 15px !important;
    }
    .react-datepicker__year-read-view--down-arrow {
      margin-top: 3px !important;
    }
    .react-datepicker__month-read-view--down-arrow {
      margin-top: 3px !important;
    }
    .react-datepicker__year-dropdown--scrollable {
      border: 1px solid #d3d5d7 !important;
      margin-left: 49px;
      top: 38px;
      padding: 10px;
      text-align: left;
    }
    .react-datepicker__month-option:hover {
      background: #e6e6e6;
      color: #000;
    }
    .react-datepicker__month-dropdown {
      left: 14px !important;
      border: 1px solid #d3d5d7 !important;
    }
    .react-datepicker__month-read-view {
      visibility: visible !important;
    }
    .react-datepicker__year-read-view {
      visibility: visible !important;
    }
    .react-datepicker__day-names {
      border-top: 1px solid #d3d5d7 !important;
      margin-bottom: -16px;
      padding-top: 9px;
    }
    .react-datepicker__header__dropdown {
      padding-bottom: 8px !important;
    }
    .react-datepicker__month-option--selected_month {
      background: #0088ff;
      color: #ffff;
    }
    .react-datepicker__month-option--selected {
      display: none !important;
    }
    .react-datepicker__year-option--selected {
      display: none !important;
    }
    .react-datepicker__year-option--selected_year {
      background: #0088ff;
      color: #ffff;
    }
    .react-datepicker__year-option:hover {
      background: #e6e6e6;
      color: #000;
    }
  }
  .react-datepicker__day--selected {
    background-color: #0088ff !important;
    border-radius: 14px !important;
  }
  .react-datepicker {
    border: 1px solid #d3d5d7 !important;
  }
  .react-datepicker__navigation:hover {
    .react-datepicker__navigation-icon::before {
      border-color: #0088ff !important;
    }
  }

  .react-datepicker__navigation-icon {
    font-size: 11px !important;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: #0088ff !important;
    border-radius: 14px !important;
  }
  .react-datepicker__triangle::after {
    border-bottom-color: #fff !important;
  }
  .ant-input-number-lg {
    font-size: 14px !important;
  }
  .ant-select-selection-item {
    font-size: 14px !important;
  }
  .ant-input-lg {
    font-size: 14px !important;
  }
  .ant-radio-wrapper {
    font-size: 14px !important;
  }
  .ant-form-item {
    margin-bottom: 16px !important;
  }
  .ant-form-item-label {
    padding: 0 0 0 4px !important;
  }
}
.ant-form-item-has-error {
  .react-datepicker__input-container {
    #register-lead-form_dob {
      border-color: #ff4d4f !important;
    }
    #register-lead-form_icIssuedOn {
      border-color: #ff4d4f !important;
    }
  }
}
.custom-message-success {
  .ant-message-notice-content {
    background: #0db473;
    color: #fff;
    padding: 10px 50px 10px 50px;
  }
  .anticon {
    color: #fff !important;
  }
}
.btn-copy {
  background: #fff;
  border: 1px solid #d3d5d7;
  font-size: 14px;
  padding: 8px;
  border-radius: 3px;
  outline: none;
  &:hover {
    font-weight: bold;
  }
}
.loan_menu {
  background-color: #ffffff;
}
.active-container {
  border-bottom: 3px #007bff solid;
  color: #0088ff;
}
.title-tab {
  display: flex;
  width: 96%;
  margin: auto;
  height: 100%;
  & div {
    cursor: pointer;
    margin-right: 48px;
    padding-top: 16px;
  }
  color: #747c87;
  font-weight: 'bold';
}
.label-right-detail {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #747c87;
  max-width: 50%;
  flex-basis: 50%;
}
.value-right-detail {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  max-width: 75%;
  color: #000;
  max-width: 50%;
  flex-basis: 50%;
  text-align: right;
}
.banner-container{
  border-radius: 6px;
  padding: 8px 16px;
  font-weight: 400px;
  font-size: 14px;
  min-height: 40px;
  display: flex;
  img {
    margin: 5px 10px;
  }
  span {
    margin: 5px 0;
  }
}
.banner-success{
  background-color: #F3FCF9;
  border: 1px solid #0DB473;
}
.banner-info{
  background-color: #F2F9FF;
  border: 1px solid #007CE8;
}
.banner-warning{
  background-color: #FFF7E7;
  border: 1px solid #FFDF9B;
}
