.TransferContainer{
    // width: 94%;
    // margin: auto;
    div,span{
        font-family: 'Helvetica Neue';
        font-style: normal;
    }
    .ConnectBankNotFound{
        div{
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #182537;
        }
        span{
            color: #0088FF;
            cursor: pointer;
        }
      
    }
    .Info{
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #182537;
        // padding-top: 9px;
        padding-bottom: 16px;
    }
    .OptionBanksContainer{
        margin-top: 10px;
        .ant-row{
            height: 557px;
            overflow-y: scroll;
            &::-webkit-scrollbar-track{
                border-radius: 10px;
                background-color: #f4f4f4;
            }
            &::-webkit-scrollbar {
                width: 4px;
                background-color: #f4f4f4;
                border: none;
            }
            
            &::-webkit-scrollbar-thumb {
                background: #D7DADD;
                border-radius: 6px; 
            }
        }
    }
    .ButtonTransfer{
        background-color: #0088FF;
        // margin-top: 24px;
        border-radius: 3px;
        width: 146px;
        height: 40px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        padding-top: 9px;
        cursor: pointer;
    }
    .BankTranferContainer{
        background: #FFFFFF;
        box-shadow: 0px 4px 8px rgba(168, 168, 168, 0.25);
        border-radius: 5px;
        height: 163px;
        text-align: center;
        img{
            margin-top: 24px;
        }
        .Title{
            margin-top: 9px;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #182537;
        }
        .ButtonOptions{
            margin-top: 24px;
            display: flex;
            justify-content: center;
            // & div:first-child{
            //     margin-right: 24px;
            // }
        }
    }
    .TransferSettingContainer{
        margin-top: 24px;
        .TitleTransferContainer{
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #182537;
            margin-bottom: 24px;
        }
        .OptionTranferContainer{
            background-color: #FFFFFF;
            box-shadow: 0px 8px 16px rgba(168, 168, 168, 0.25);
            border-radius: 3px;
            height: 134px;
            margin-bottom: 24px;
            text-align: center;
            border: 1px solid transparent;
            cursor: pointer;
            transition: 0.5s;
            // z-index: -1;
            img{
                margin-top: 16px;
                height: 72px;
                object-fit: contain;
            }
            .Content{
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #182537;
                margin-top: 16px;
            }
            .SelectIcon{
                height: 12px;
                object-fit: contain;
                position: absolute;
                top: -10px;
                right: 4px;
            }
            .ActiveTypeTranfer{
                overflow: hidden;
                position:absolute;
                margin:-20px;
                width:60px;
                height:28px;
                transform:rotate(45deg);
                background-color:#0088FF;
                top: 17px;
                right: -1px;
            }
        }
        .ActiveContainer{
            border: 1px solid #0088FF;
        }
        .TransferMoneySettingContainer{
            width: 98%;
            margin: auto;
            background-color: #FFFFFF;
            border-radius: 3px;
            padding-bottom: 16px;
        }
        .Title{
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #0088FF;
            padding-top: 16px;
            margin-bottom: 16px;
        }
        .BankContainer{
            background: #F4F6F8;
            border-radius: 3px;
            .BankHeaderContainer{
                width: 98%;
                margin: auto;
                padding: 9px 0px;
                img{
                    width: 20px;
                    height: 20px;
                    object-fit: none;
                }
                span{
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #182537;
                    margin-left: 10px;
                }
            }
        }
    }
}