$PrimaryColors: (#0088ff, #33a0ff, #66b8ff, #99cfff, #cce7ff, #f2f9ff);
$Base1Colors: (#182537, #46515f, #747c87, #a3a8af, #e8eaeb, #f3f4f5);
$Base2Colors: (#212b35, #4d555d, #7a8086, #7a8086, #a6aaae, #d3d5d7, #f4f4f5);
$InformationColors: (#0088ff, #33a0ff, #66b8ff, #99cfff, #cce7ff, #f2f9ff);
$SuccessfulColors: (#0fd186, #3fda9e, #6fe3b6, #9fedcf, #cff6e7, #f3fcf9);
$WarningColors: (#ee7e16, #f19b45, #f5b173, #f8cba2, #fef3e8, #fef8f3);
$ErrorColors: (#e4193e, #e94765, #ef758b, #f4a3b2, #fde8ec, #fdf3f5);
$SPBackgroundColor: #f4f4f4;
$PopupColor: rgba(244, 246, 248, 0.4);
$MenuBackgroundColor: #182537;
$MenuHoverColor: #243041;
$font-sizes: (
  'xl': 28px,
  'l': 20px,
  'm': 16px,
  's': 14px,
  'xs': 12px,
);
$font-family: (
  'Roboto': Roboto,
  'Helvetica': 'Helvetica Neue',
);
$font-weight: (
  'medium': 600,
  'regular': normal,
  'bold': bold,
  'light': lighter,
);
$line-height: (
  'xl': 36px,
  'l': 28px,
  'm': 20px,
  's': 18px,
  'xs': 16px,
);
body {
  font-size: map-get($font-sizes, 'm');
  font-family: map-get($font-family, 'Helvetica'), sans-serif;
  font-weight: map-get($font-weight, 'regular');
  background-color: $SPBackgroundColor;
}
#root {
  height: 100%;
}
.Shadow8 {
  box-shadow: 0px 4px 8px 0px rgba(168, 168, 168, 0.25);
}
.Shadow4 {
  box-shadow: 0px 2px 4px 0px rgba(168, 168, 168, 0.25);
}
.Brand1 {
  color: nth($PrimaryColors, 1);
}
.Brand2 {
  color: nth($SuccessfulColors, 1);
}
.wrapper {
  background-color: $SPBackgroundColor;
  padding: 0;
  margin: 0;
  height: 100%;
}
.center-wrapper {
  margin: 20% auto;
  width: 900px;
}
.paper {

  background-color: #ffffff;
}
.header {
  height: 64px;
  background-color: #ffffff;
  font-size: map-get($font-sizes, 'l');
  font-weight: map-get($font-weight, 'medium');
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0px;
}
.product-header {
  background-color: inherit;
  font-size: map-get($font-sizes, 'l');
  font-weight: map-get($font-weight, 'medium');
  display: flex;
  align-items: center;
}
.header > .greeting {
  margin-left: 32px;
}
.header > .title {
  margin-left: 32px;
}
.product-header > .title {
  margin-left: 32px;
}
.header > .support-content {
  margin-left: auto;
  margin-right: 32px;
  display: none;
}
.product-header > .support-content {
  margin-left: auto;
  margin-right: 32px;
}
.product-nav {
  padding-top: 16px;
  font-size: map-get($font-sizes, 's');
}
.product-nav > .breadcrumb {
  margin: 0;
  padding-left: 32px;
  line-height: 16px;
  background-color: inherit;
}
.product-back-item {
  cursor: pointer;
  color: inherit !important;
}
.product-back-icon {
  margin-right: 12px;
}
.support-btn {
  color: #212529;
  border: 1px solid #c4cdd5;
  padding: 0 20px;
  line-height: 36px;
}
.support-btn:hover {
  border: 1px solid #c4cdd5;
}
.support-item-link {
  text-decoration: none !important;
  :hover {
    text-decoration: none !important;
    background-color: nth($PrimaryColors, 1);
    color: #ffffff;
  }
}
.support-item-content {
  width: 124px;
  text-align: center;
}
.support-btn > .icon-support {
  margin-right: 8px;
}
.introduction {
  margin: 32px;
  display: flex;
  justify-content: center;
}
.introduction-content {
  text-align: center;
  width: 480px;
  margin: 40px 108px 0px 0px;
}
.logo-wrapper {
  width: 220px;
  margin: 0 auto;
}
.feature-container {
  margin: 0px 20px;
}
.feature {
  padding: 0;
  margin: 0;
}
.feature-item {
  margin: 4px 12px;
  padding: 0px 20px;
  text-align: center;
}
.feature-item > .icon {
  margin: 32px auto 16px;
}
.feature-item > .content {
  padding-bottom: 16px;
}
.feature-item > .content > .title {
  font-weight: map-get($font-weight, 'medium');
}
.feature-item > .content > .detail {
  font-size: map-get($font-sizes, 's');
}
.limit-content-container {
  min-height: 196px;
  text-align: center;
  margin: 0 32px;
  margin-top: 24px;
}

.limit-content-container > .limit-content {
  padding: 20px;
}
.limit-content-container > .limit-content > .title {
  font-size: map-get($font-sizes, 'l');
}
.limit-content-container > .limit-content > .limit-amount {
  font-size: map-get($font-sizes, 'xl');
  font-weight: map-get($font-weight, 'bold');
  line-height: map-get($line-height, 'xl');
  color: nth($PrimaryColors, 1);
}
.feature-item > .content > .limit-amount {
  font-size: map-get($font-sizes, 'xl');
  font-weight: map-get($font-weight, 'bold');
  line-height: map-get($line-height, 'xl');
  color: nth($PrimaryColors, 1);
  margin-bottom: 10px;
}
.main-home-button {
  font-size: map-get($font-sizes, 's');
  line-height: map-get($line-height, 's');
  width: 206px;
  height: 48px;
  background-color: nth($PrimaryColors, 1);
  border-radius: 3px;
  border: 1px solid nth($PrimaryColors, 1);
  margin: 8px;
}
.home-button {
  font-size: map-get($font-sizes, 's');
  line-height: map-get($line-height, 's');
  width: 206px;
  height: 48px;
  color: nth($PrimaryColors, 1);
  // background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid nth($PrimaryColors, 1);
  &:hover {
    color: #ffffff;
    background-color: nth($PrimaryColors, 1);
    border-color: nth($PrimaryColors, 1);
  }
  margin: 8px;
}

/*modal dashboard*/
.register-modal {
  padding: 0;
}
.register-modal > .mdheader {
  text-align: center;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // justify-items: center;
  margin: 32px auto;
}
.register-modal > .mdheader > .title {
  font-size: map-get($font-sizes, 'l');
  font-weight: map-get($font-weight, 'medium');
  line-height: map-get($line-height, 'l');
}
.register-modal > .mdheader > .intro {
  font-size: map-get($font-sizes, 's');
}
.modal-close-btn {
  position: absolute;
  top: 12px;
  right: 12px;
}
.logo-group {
  display: flex;
  margin-top: 20px;
  margin-bottom: 12px;
  justify-content: center;
}
.register-modal > .mdheader > .logo-group > .sapo-fin-logo {
  width: 200px;
  margin-left: 12px;
}
.group-label {
  font-weight: map-get($font-weight, 'medium');
  margin-left: 32px;
}
.form-label.modal-form-label {
  font-size: map-get($font-sizes, 'xs');
  line-height: map-get($line-height, 'xs');
}
.form-control.modal-form-input {
  height: 40px;
  font-size: map-get($font-sizes, 's');
  &:hover,
  &:focus {
    border: 1px solid nth($PrimaryColors, 1);
    box-shadow: none;
  }
}
.modal-form-row {
  margin: 6px 32px;
}
.modal-group-submit {
  margin: 0 auto 32px;
}
.form-check-label {
  font-size: map-get($font-sizes, 's');
}
.modal-term-label {
  font-style: italic;
  color: #000 !important;
}
.invalid-term-cb {
  border-color: #ff0000;
}
.hidden-input {
  display: none;
  visibility: hidden;
}
.error-input {
  border: 1px solid #dc3545;
}
/*global effect*/
.requirement-mark {
  color: #ff0000;
  font-weight: 700;
}
.footer-note {
  // position: absolute;
  // bottom: 15px;
  // left: 32px;
  font-size: map-get($font-sizes, 'xs');
  margin: 32px 0 12px 32px;
}

.wide-btn {
  font-size: map-get($font-sizes, 's');
  line-height: map-get($line-height, 's');
  height: 48px;
  border-radius: 3px;
  border: 1px solid nth($PrimaryColors, 1);
  color: #ffffff;
  background-color: nth($PrimaryColors, 1);
  border-color: nth($PrimaryColors, 1);
  margin: 8px;
}
.success-color {
  color: nth($SuccessfulColors, 1) !important;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none;
}
/*product form*/
.form-label.main-form-label {
  font-size: map-get($font-sizes, 's');
  line-height: map-get($line-height, 's');
}

.form-control.main-input {
  height: 48px;
  font-size: map-get($font-sizes, 'm');
  &:hover,
  &:focus {
    border: 1px solid nth($PrimaryColors, 1);
    box-shadow: none;
  }
}
.money-group {
  position: relative;
}
.money-group > .currency-symbol {
  position: absolute;
  right: 12px;
  top: 42px;
  font-size: map-get($font-sizes, 's');
}
.term-group {
  position: relative;
}
.suffix-symbol {
  position: absolute;
  right: 12px;
  top: 42px;
  font-size: map-get($font-sizes, 's');
}
.main-form {
  margin: 20px;
}
.main-form-row {
  padding: 0;
  margin: 0;
}
.main-form-group {
  padding: 0;
  margin: 0 12px;
}
.product-container {
  display: flex;
  flex-direction: column;
  min-height: 128px;
  margin: 16px 17px 0;
}
.product-row {
  min-height: 96px;
  font-size: map-get($font-sizes, 's');
  line-height: map-get($line-height, 's');
}
.product-row-control {
  color: #4697fe;
  background-color: nth($PrimaryColors, 6);
  min-height: 32px;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  font-size: map-get($font-sizes, 's');
  padding: 4px;
}
.product-detail-container {
  margin: 0 17px;
}
.rating > .rating-detail > .rating-icon {
  margin: 0 4px;
}
.list-requirement {
  margin: 12px 12px;
  list-style: none;
  padding: 0;
  font-size: map-get($font-sizes, 's');
}
.requirement-item {
  margin-top: 12px;
}
.requirement-item > .icon {
  margin-right: 4px;
}
.lst-repayment-tbl-container {
  height: 356px;
}
.lst-repayment-tbl {
  margin-top: 12px;
  margin-bottom: 0;
  font-size: map-get($font-sizes, 's');
  line-height: map-get($line-height, 's');
}
.lst-repayment-tbl .MuiTableCell-root {
  height: 48px;
}
.lst-repayment-tbl th {
  text-align: center;
}
.lst-repayment-tbl td {
  text-align: center;
}
.partner-logo {
  text-align: center;
  margin: auto;
}
.partner-logo > .logo {
  height: 60px;
}
.loan-product {
  text-align: center;
  margin: auto;
}
.loan-product > .product-name {
  // color: nth($PrimaryColors, 1);
}
.monthly-repayment {
  text-align: center;
  margin: auto;
}
.monthly-repayment > .repayment-detail {
  font-size: map-get($font-sizes, 'm');
  line-height: map-get($line-height, 'm');
  font-weight: map-get($font-weight, 'medium');
}
.monthly-repayment > .repayment-info {
  margin-top: 12px;
}
.approval-time {
  text-align: center;
  margin: auto;
}
.approval-time > .approval-time-detail {
  font-size: map-get($font-sizes, 'm');
  line-height: map-get($line-height, 'm');
  font-weight: map-get($font-weight, 'medium');
}
.approval-time > .approval-time-info {
  margin-top: 12px;
}
.successful-rate {
  text-align: center;
  margin: auto;
}
.successful-rate > .successful-rate-detail {
  font-size: map-get($font-sizes, 'm');
  line-height: map-get($line-height, 'm');
  font-weight: map-get($font-weight, 'medium');
}
.successful-rate > .successful-rate-info {
  margin-top: 12px;
}
.rating {
  text-align: center;
  margin: auto;
}
.rating > .rating-info {
  margin-top: 12px;
}
.register-control {
  text-align: center;
  margin: auto;
}
.middle-section {
  display: flex;
}
.intro-product {
  margin-left: 32px;
}
.intro-product > .shop-name-hl {
  // color: nth($PrimaryColors, 1);
}
.order-by-wrapper {
  margin-left: auto;
  margin-right: 32px;
}
.not-found-img {
  margin: 48px auto;
}
.return-home-btn {
  margin: 24px auto;
}
.modal-partner-logo {
  width: 200px;
  margin-right: 12px;
}
.modal-partner-logo > img {
  height: 64px;
}
.sapo-fin-logo > img {
  height: 64px;
}
.noti-successful-content {
  text-align: center;
  font-size: map-get($font-sizes, 's');
  width: 650px;
  margin: 12px auto;
  span {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    color: #46515F;
    font-size: 16px;
    line-height: 20px;
  }
  .btn-view-application{
    width: 250px;
    height: 48px;
  }
}
.noti-successful-title {
  margin-top: 24px;
  span {
    font-weight: 600 !important;
    font-size: 20px !important;
    color: #0F1824 !important;
  }
}
.noti-contact-info {
  margin: 12px auto;
}
.noti-sc-btn {
  margin-top: 20px;
  font-weight: map-get($font-weight, 'medium');
}
.noti-successful-img {
  margin: 36px auto;
}
.sqr-icon {
  margin: 0 12px;
}
.first-register-form {
  width: 500px;
  margin: 300px auto;
}
.register-input {
  width: 200px;
}
/*list app*/
.list-app-title {
  font-size: map-get($font-sizes, 'l');
  font-weight: map-get($font-weight, 'medium');
  margin: 16px auto;
}
.tbl-header {
  font-weight: map-get($font-weight, 'medium') !important;
}
/*loading*/
.fixed-loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999;
  background: #41bef566;
  opacity: 0.3;
}

.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  z-index: 100000;
  margin: auto;
  position: fixed;
  top: 48%;
  right: 48%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// css application_history
.active-container-aplication {
  border-bottom: 3px #007bff solid;
}

.title-application {
  display: flex;
  & div {
    cursor: pointer;
    margin: 10px 30px 10px 20px;
  }
  // & div:hover {
  //   color: #66b8ff;
  // }
  font-weight: map-get($font-weight, 'bold');
}
.table-application {
  margin: 20px 30px;
}
.application-history {
  min-height: 40px;
  margin: 0 32px;
  margin-top: 24px;
}
.tbl-header-applicaction {
  // width: 20%;
  padding-left: 20 px;
  font-weight: map-get($font-weight, 'medium') !important;
}
// .tbr-update-application {
//   margin-top: -10px;
//   margin-bottom: -10px;
// }
.rol-status {
  margin-top: 10px;
  margin-left: 10px;
}
.btn-upload-file {
  height: 30px;
  width: 150px;
  background: #ffffff;
  border: 1px solid #c4ccde;
  box-sizing: border-box;
  border-radius: 5px;
}
.img-upload-file {
  margin-left: -40px;
}
.btn-update {
  margin-top: 25px;
  text-align: center;
  margin-bottom: 10px;
}
.hidden-row {
  display: none !important;
}
.dialog-body {
  padding-left: 25px;
  padding-right: 25px;
}
.upload-file-body {
  // height: 100px;
  // background-color: #fafafa;
  // padding-top: 40px;
  // border: #a6aaae;
  // border-style: dashed;
  flex: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 24s ease-in-out;
}
.btn-edit-dialog {
  width: 74px;
  height: 36px;
  margin-right: 20px;
  background: linear-gradient(180deg, #fff, #f9fafb);
  border: 1px solid #c4cdd5;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border-radius: 4px;
  font-size: 14;
  font-weight: 400;
  & :active {
    background-image: none;
  }
  & :focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(196, 205, 213, 0.25);
  }
}

.btn-delete-pictrue {
  width: 50px;
  height: 28px;
  border-radius: 4px;
  margin-left: 30px;
  background: linear-gradient(180deg, #fff, #f9fafb);
  border: 0px solid #c4cdd5;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  font-size: 14;
  font-weight: 400;
}

.slick-dots {
  bottom: -25px;
}
.slick-arrow {
  background-color: 'red' !important;
}

.parent {
  margin: auto;
  width: 505px;
  height: 305px;
  border: 1px solid black;
}
.child {
  margin: auto;
  width: 500px;
  height: 300px;
}
.action {
  display: flex;
  margin-top: 5px;
  width: 500px;
  margin: auto;
  text-align: center;
}
.tbn-remove {
  text-align: center;
  font-size: 1.6rem;
  margin: auto;
  margin-right: 10px;
}
.tbn-remove :hover {
  cursor: pointer;
  color: blue;
}
.icon-close-dialog {
  position: absolute;
  right: 15px;
  top: 15px;
}
.icon-close-dialog :hover {
  background-color: #eeeeee;
}
.icon20 {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.root {
  height: 390px;
  overflow: hidden;
  & .slick-dots {
    bottom: -25px;
  }
  & .slick-arrow {
    background-color: #b3abab;
    height: 100px;
  }
  & .slick-slide img {
    display: inline;
  }
}
.no-file {
  height: 200px;
  margin: auto;
  text-align: center;
}
.icon-delete {
  width: 30px;
  margin-top: 5px;
}
.file-title {
  word-break: break-all;
  width: 400px;
}
.dialog-action {
  border-top: 1px solid #ddd;
  font-size: 14px;
}
.star {
  color: red;
  font-size: large;
}
.btn-delete-file {
  background-color: white;
  border: 0px;
  margin-left: 250px;
}
.picture-pdf {
  width: 500px;
  height: 300px;
  margin: auto;
}
.btn-delete-dialog {
  width: 74px;
  height: 36px;
  margin-right: 10px;
  color: white;
  background: #ff4d4d;
  border: 1px solid #c4cdd5;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border-radius: 4px;
  font-size: 14;
  font-weight: 400;
  & :active {
    background-image: none;
  }
  & :focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(196, 205, 213, 0.25);
  }
}
.product-back-icon-calculator {
  margin-right: 12px;
  margin-left: 12px;
}
.btn-back{
  color: #747C87;
  cursor: pointer;
  font-weight:normal;
}
// end css application_histoy
