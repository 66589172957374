.date-picker__root {
  position: relative;

  &:hover {
    .date-picker__button_root {
      border: 1px solid #0088ff;
    }
  }
}

.date-picker__lib_picker_range {
  .rdrDateDisplayWrapper {
    display: none;
  }
  .rdrMonthAndYearWrapper {
    padding: 2px 0px;
    border-bottom: 1px solid #d3d5d7;
    height: auto;
    & > button:focus {
      outline: none;
    }
    .rdrMonthAndYearPickers {
      & > span > select:hover {
        // background-color: white;
      }
    }
  }
}

.date-picker__dropdown {
  opacity: 1 !important;
  visibility: visible !important;
}

.hidden_date_picker {
  visibility: hidden;

  display: flex;
  position: absolute;
  top: 45px;
  flex-direction: column;
  transition: opacity 0.2s, visibility 0s;

  max-width: 332px;
  box-sizing: border-box;
  border-radius: 3px;

  background: white;
  transform: translateX(-23%);
  opacity: 0;
  z-index: 10;

  border: 1px solid #d3d5d7 !important;

  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;

  &::before {
    content: ' ';
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-left: 1px solid #d3d5d7;
    border-top: 1px solid #d3d5d7;
    transform: rotate(45deg);
    z-index: 10;
    top: -6px;
    left: 150px;
  }
}

.date-picker__lib_content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 5px;
  border-bottom: 1px solid #d3d5d7;
  align-items: center;
}

.date-picker__lib_content_item {
  width: 122px;
  height: 32px;
  border: 1px solid #d3d5d7;
  box-sizing: border-box;
  border-radius: 3px;

  font-size: 14px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.active {
  border: 1px solid #0088ff;
}

.date-picker__button_root {
  display: flex;
  min-width: 120px;
  max-width: 160px;
  width: 160px;
  height: 36px;

  background: #ffffff;
  border: 1px solid #e8eaeb;
  box-sizing: border-box;
  border-radius: 0px;

  padding: 10px 12px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-size: 14px;
  cursor: pointer;
  transition: 0.2s;
  color: #747c87;

  &:hover {
    border: 1px solid #0088ff;
  }

  @media (max-width: 1100px) {
    max-width: 140px;
  }
}
