.pay-later__root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px 32px 24px 32px;
  font-family: 'Helvetica Neue';  
}

.pay-later__bread_cum {
  .ant-breadcrumb {
    font-size: 18px;
    cursor: pointer;
    // &:first-of-type {
    //   position: relative;
    //   .ant-breadcrumb-separator {
    //     position: absolute;
    //     top: -4px;
    //   }
    // }
    // &:last-of-type {
    //   .ActiveLinkContainer {
    //     margin-left: 35px;

    //   }
    // }
    .anticon{
      vertical-align: middle;
    }
  }
  margin-bottom: 10px;
}