$PrimaryColors: (#0088ff, #33a0ff, #66b8ff, #99cfff, #cce7ff, #f2f9ff);
$Base1Colors: (#182537, #46515f, #747c87, #a3a8af, #e8eaeb, #f3f4f5);
$Base2Colors: (#212b35, #4d555d, #7a8086, #7a8086, #a6aaae, #d3d5d7, #f4f4f5);
$InformationColors: (#0088ff, #33a0ff, #66b8ff, #99cfff, #cce7ff, #f2f9ff);
$SuccessfulColors: (#0fd186, #3fda9e, #6fe3b6, #9fedcf, #cff6e7, #f3fcf9);
$WarningColors: (#ee7e16, #f19b45, #f5b173, #f8cba2, #fef3e8, #fef8f3);
$ErrorColors: (#e4193e, #e94765, #ef758b, #f4a3b2, #fde8ec, #fdf3f5);
$SPBackgroundColor: #f4f4f4;
$PopupColor: rgba(244, 246, 248, 0.4);
$MenuBackgroundColor: #182537;
$MenuHoverColor: #243041;
$PrimaryFontFamily: 'Helvetica Neue', sans-serif;
$font-sizes: (
  'xl': 28px,
  'l': 20px,
  'm': 16px,
  's': 14px,
  'xs': 12px,
);
$font-family: (
  'Roboto': Roboto,
  'Helvetica': 'Helvetica Neue',
);
$font-weight: (
  'medium': 600,
  'regular': normal,
  'bold': bold,
  'light': lighter,
);
$line-height: (
  'xl': 36px,
  'l': 28px,
  'm': 20px,
  's': 18px,
  'xs': 16px,
);
// .breadcrumHeader {
//   font-size: 18px;
//   line-height: 24px;
//   font-weight: map-get($font-weight, 'medium');
//   margin-left: 32px;
// }

.ant-form-item-label {
  span {
    font-size: 14px !important;
  }
}

.registerLeadModal {
  .ant-modal-header {
    border-bottom: none;
  }
  top: 48px !important;
  .sqr-icon-register-lead {
    margin: 0px 12px 3px 12px;
  }
  .ant-modal-close-x {
    font-size: 24px;
  }
  .RegisterLeadStepsContainer {
    width: 50%;
    margin: auto;
    .ant-steps-icon {
      font-family: $PrimaryFontFamily;
      font-size: 16px;
      line-height: 20px;
    }
    .anticon {
      vertical-align: middle;
    }
    .RegisterLeadStepFinishContainer {
      margin-bottom: 24px;
    }
  }
  .ant-modal-close:focus{
    border: none !important;
    outline: none;
  }
  .RegisterLeadHeaderRegisterModal {
    text-align: center;
    .RegisterLeadTitleModalContainer {
      margin-top: 8px;
      width: 100%;
      font-family: 'Helvetica Neue', sans-serif;
      font-weight: 700;
      font-size: 28px;
      line-height: 24px;
      color: #0F1824;
    }
    .register-lead-sapo-fin-logo {
      img {
        height: 64px;
      }
    }
  }
  .RegisterLeadFormContainer {
    margin-top: 24px;
    font-family: $PrimaryFontFamily;
    .ant-input:hover {
      border-color: nth($PrimaryColors, 1);
    }
    .ant-select-selection-placeholder,
    .ant-input:placeholder-shown,
    .ant-input-number-input:placeholder-shown,
    .ant-picker-input > input:placeholder-shown {
      font-size: 14px !important;
    }
    .ant-picker-input {
      input {
        font-size: 14px !important;
      }
    }
    .ant-form-item-label {
      padding: 0 !important;
    }
    .ant-modal-close {
      font-size: 24px !important;
    }
    .ant-form-item-explain {
      font-size: 13px;
      font-family: $PrimaryFontFamily;
    }
    .ant-select-selection-item {
      font-size: 14px !important;
    }
    .ant-form-item-label > label {
      height: 32px !important;
      font-size: 14px !important;
      color: #747c87 !important;
    }
    .RegisterLeadFormTitle {
      font-family: Helvetica Neue;
      font-weight: 600 !important;
      font-size: 14px;
      line-height: map-get($line-height, 'm');
      color: #212B35;
      margin-bottom: 5px;
    }
    .RegisterLeadSubmitButton {
      width: 128px !important;
      height: 48px !important;
      // background-color: nth($PrimaryColors, 1);
      border-radius: 3px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
    .register-control {
      text-align: center;
      margin: auto;
    }
    .register-btn {
      height: 48px;
      font-size: map-get($font-sizes, 's');
    }
    .ant-form-item-required::before {
      display: none;
    }
    .ant-form-item-label > label::after {
      margin-left: 4px;
      color: #ff0000;
      font-weight: 700;
      content: '*';
    }
    .register-lead-form-item {
      margin-bottom: 12px !important;
    }
    .register-lead-form-input {
      width: 99% !important;
      height: 40px !important;
      border-radius: 3px !important;
      font-size: 14px !important;
      font-family: $PrimaryFontFamily !important;
    }
    #register-lead-form label::before {
      display: none;
    }
    .RegisterLeadTermConditionContainer {
      font-family: 'Helvetica Neue', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #747c87;
      padding: 0 !important;
    }
  }
}
