.AccessControlContainer{
    // width: 94%;
    margin: auto;
    // margin-top: 1%;
    line-height: 22px;
    div{
        font-family: 'Helvetica Neue';
        font-style: normal;
        color: #182537;
    }
    .SettingRoleInfo{
        font-weight: bold;
        font-size: 18px;
    }
    .ButtonContainer{
        width: 160px;
        height: 40px;
        background: #0088FF;
        border-radius: 3px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        padding-top: 11px;
        text-align: center;
        // display: flex;
        // flex-direction: column-reverse;
        svg{
            padding-bottom: 2px;
        }
    }
    .ant-table-cell{
        padding-left: 0;
    }
    .IdTableContainer{
        padding-left: 16px;
        text-align: center;
    }
    .ant-table-tbody{
        .IdTableContainer{
            padding-right: 16px;
        }
    }
    .EmployeesPromiseContainer{
        background-color: #FFFFFF;
        margin-top: 2%;
        th{
            font-family: 'Helvetica Neue';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #182537;
        }
        td{
            font-family: 'Helvetica Neue';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #182537;
        }
        & span,p,a{
            font-family: 'Helvetica Neue';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #182537;
            font-size: 14px;
            line-height: 17px;
        }
        .ant-pagination-item{
            margin-top: 6px;
            padding-top: 3px;
        }
        .ant-pagination-item-active{
            // padding-top: 2px;
            color: white;
            background-color:#0088FF;
            border: none;
            border-radius: 50%;
            a{
                color: #FFFFFF;
            }
        }
        .ant-pagination-options{
            display: none;
        }
        .ant-select-arrow{
            svg{
                width: 10px;
                height: 12px;
            }
        }
        .ant-pagination-disabled{
            opacity: 50%;
            &:hover{
                cursor: default;
            }
        }
        .ant-pagination-item-link-icon{
            padding-top: 6px;
        }
        .ant-pagination-item-ellipsis{
            padding-top: 8px;
        }
        .EmployeesTableContainer{
            .ant-table-body{
                height: 473px;
                &::-webkit-scrollbar-track{
                    border-radius: 10px;
                    background-color: #FFFFFF;
                }
                &::-webkit-scrollbar {
                    width: 4px;
                    background-color: #FFFFFF;
                    border: none;
                }
                
                &::-webkit-scrollbar-thumb {
                    background: #D7DADD;
                    border-radius: 6px; 
                }
        }
        }
    }
}


//tags==========
.TagsContainer{
    width: 129px;
    height: 29px;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 17px !important;
    // text-align: center;
    color: #EE4747 !important;
    background-color : #FFF6F6;
    border-radius: 40px;
    padding-top: 6px;
    text-align: center;
}
.PromiseAccept{
    color: #0DB473 !important;
    background-color : #F3FCF9;
}

.AccessControlSettingDetailContainer{
    // width: 94%;
    margin: auto;
    font-family: 'Helvetica Neue';
    font-style: normal;
    color: #212B35;
    font-weight: normal;
    // margin-top: 1%;
    .ButtonContainer{
        width: 160px;
        height: 40px;
        background-color: #0088FF;
        border-radius: 3px; 
        text-align: center;
        padding-top: 4px;
        color: #FFFFFF;
        padding-top: 9px;
        cursor: pointer;
    }
    .ButtonCustom{
        background-color: #FFFFFF;
        color: #0088FF;
        border: 1px solid #0088FF;
        margin-right: 2%;
    }
    .EmployeeTitle{
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
    }
    .SubTitle{
        font-size: 14px;
        line-height: 17px;
        color: #747C87;
        margin-top: 5px;
    }
    .FunctionContainer{
        background: #FFFFFF;
        border: 1px solid #E8EAEB;
        box-sizing: border-box;
        border-radius: 5px;
        margin-top: 1%;
        .FunctionContentContainer{
            width: 95%;
            margin: auto;
            margin-top: 2%;
            margin-bottom: 2%;
            .ant-checkbox-group{
                width: 100%;
            }
            .TitleFunction{
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 1%;
            }
         
            
        }
    }
}