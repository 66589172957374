.pay-later-status__paid {
  max-width: 120px;
  background: #F3FCF9;
  border-radius: 40px;
  color: #0DB473;
  text-align: center;
  padding: 5px;

}

.pay-later-status__unpaid {
  max-width: 120px;
  background: rgba(255, 227, 203, 0.4);
  border-radius: 40px;
  color: #FF7400;
  text-align: center;
  padding: 5px;

}

.pay-later-status__cancel {
  background: #FFF6F6;
  border-radius: 40px;
  max-width: 120px;
  text-align: center;
  color: #EE4747;
  padding: 5px;

}