.SapoFin {
  background-color: #f4f4f4;
  padding:0px 0px 10px 0;
  font-family: 'Helvetica Neue', sans-serif;
  .SapoMoneyContainer{
    margin-top: 100px !important;
    margin: 0 32px;
    background-color: #fff;
    padding: 30px 0px;
    border-radius: 3px;
    .SapoMoneyHeaderContainer{
      margin: 0 32px;
      .title{
        h5{
          font-weight: bold;
          font-size: 20px;
          line-height: 10px;
          color: #0F1824;
        }
      }
      .description{
        font-size: 16px;
        color: #747C87;
        margin-bottom: 20px;
      }
      .subDescription{
        i{
          margin-right: 5px;
        }
        span{
          color: #747C87;
          font-size: 14px;
          margin-right: 45px;
        }
      }
      .btn{
        font-size: 16px !important;
        font-weight: 500;
      }
    }

  }
  .SapoOnlinePaymentContainer{
    margin: 0 32px;
    background-color: #fff;
    padding: 30px 0px;
    border-radius: 3px;
    .SapoMoneyHeaderContainer{
      margin: 0 32px;
      .title{
        h5{
          font-weight: bold;
          font-size: 20px;
          line-height: 10px;
          color: #0F1824;
        }
      }
      .description{
        font-size: 16px;
        color: #747C87;
        margin-bottom: 20px;
      }
      .subDescription{
        i{
          margin-right: 5px;
        }
        span{
          color: #747C87;
          font-size: 14px;
          margin-right: 45px;
        }
      }
      .btn{
        font-size: 16px !important;
        font-weight: 500;
      }
    }

  }
  .SapoFinContainer {
    margin: 0 32px;
    .SapoFinBanner {
      img {
        width: 100%;
      }
    }
    .SapoFinOptionsContainer {
      .FinOptionsContainer {
        background-color: #F2F9FF;
        border: 1px solid transparent;
        border-radius: 5px;
        margin-top: 5%;
        height: 170px;
        .FinOptionTitle {
          //   font-family: 'Helvetica Neue';
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          color: #182537;
          margin-top: 2%;
        }
        .FinOptionDescription {
          //   font-family: 'Helvetica Neue';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #747C87;
          width: 65%;
          margin: auto;
          margin-top: 2%;
        }
        .ImgFinOption {
          padding-top: 17px;
        }
      }
      .FinOptionsContainer:hover {
        cursor: pointer;
        border: 1px solid #99cfff;
        box-shadow: 0px 0px 16px rgba(16, 55, 82, 0.15);
        border-radius: 5px;
      }
      .FinOptionNotActive {
        position: relative;
        background-color: #F2F9FF;
        &:hover {
          cursor: pointer;
          border: 1px solid #99cfff;
          box-shadow: 0px 0px 16px rgba(16, 55, 82, 0.15);
          border-radius: 5px;
        }
        .FinOptionIconNotActive {
          width: 76px;
          height: 24px;
          background-color: #FDF3F5;
          border-radius: 20px;
          padding: 4px 8px;

          position: absolute;
          right: 3.52%;
          top: 7.06%;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            color: #E4193E;
            font-family: Helvetica Neue;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      
    }
    .SapoMoneySlide{
      .slide-image{
        width: 100%;
        cursor: pointer;
      }
    }
    
  }
  .SapoFinFooterRow {
    height: 68px;
    align-items: center;
  }
  .SapoFinFooter {
    height: 68px;

    background-color: #ebebeb;
    margin: 24px 0 12px;
    div {
      //   font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #747c87;
    }
    .SapoInFoCustom {
      padding-left: 8%;
      .SapoFinSuport {
        display: flex;
        flex-direction: column;
        margin-left: 2%;
      }
    }
    .SapoInFoCustomSuport {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-content: center;
      .SapoFinSuport {
        display: flex;
        flex-direction: column;
        // margin-left: 10%;
      }
    }
    .SapoInfor {
      padding-left: 8%;
      border-right: 1px solid #dddfe1;
      div {
        text-decoration: underline;
        span {
          cursor: pointer;
        }
      }
    }
  }
  .SapoMoneyBlogContainer{
    margin: 0 32px;
    .SapoMoneyBlogTitle{
      .row{
        margin: 0 32px;
        padding-bottom: 15px;
        padding-top: 15px;
        .text-left{
          .title{
            text-transform: uppercase;
            color: #0F1824;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
          }
        }
        .text-right{
          .show-more{
            color: #0088FF;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
        }
      }
      border-bottom: 1px solid #E8EAEB;
    }
    
    .SapoMoneyListBlogContainer{
      margin: 10px 32px;
      .list-blog{
        .title{
          width: 100%;
          color: #0F1824;
          font-weight: 600;
          font-size: 13px;
        }
        .created{
          color: #747C87;
          font-weight: 500;
          font-size: 12px;
        }
        .blog:hover{
          .title{
            cursor: pointer;
            color: #0088FF;
          }
        }
      }
    }
  }
  .SapoMoneyLosPayter{
    .SapoMoneyBlogTitle{
      .row{
        margin: 0 32px;
        padding-bottom: 15px;
        padding-top: 15px;
        .title{
          text-transform: uppercase;
          color: #0F1824;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
        }
      }
      border-bottom: 1px solid #E8EAEB;
    }
    .limit-content{
      margin: 15px 32px;
      padding-bottom: 30px;
      height: 70px;
      .limit-amount{
        font-weight: 700;
        font-size: 28px;
        color: #0088FF;
      }
    }
  }
  .feature-item{
    .content{
      .title{
        color: #0F1824;
        font-weight: 800;
        font-size: 16px;
        margin-bottom: 10px;
      }
      .content-detail{
        height: 180px !important;
        
      }
      .btn{
        font-size: 16px !important;
        font-weight: 500;
      }
    }
    .detail{
      font-size: 14px;
      color: #7A8086 !important;
    }
  }
  .title-blod{
    color: #0F1824;
    font-weight: 700;
    font-size: 20px;
  }
  .slick-slide{
    img{
      width: 150px !important;
      height: 70px !important;
      cursor: pointer !important;
    }
  }
}

//custome page from antd
.PaginationContainer {
  font-family: 'Helvetica Neue', sans-serif;
  margin-bottom: 16px;
  margin-top: 16px;
  padding-bottom: 16px;
  div,
  span,
  p {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #182537;
  }
  .ant-pagination-item {
    margin-top: 5px !important;
    padding-top: 1px;
    color: #747c87;
  }
  .ant-pagination-item-active {
    color: white;
    background-color: #0088ff;
    border: none;
    border-radius: 50%;
    a {
      color: #ffffff;
    }
  }
  .ant-pagination-options {
    display: none;
  }
  .ant-select-arrow {
    svg {
      width: 10px;
      height: 12px;
    }
  }
  .ant-pagination-disabled {
    opacity: 0.4;
    &:hover {
      cursor: default;
    }
  }
  .ant-pagination-item-link-icon {
    padding-top: 6px;
  }
  .ant-pagination-item-ellipsis {
    padding-top: 8px;
  }
  .ant-select-selection-item {
    font-size: 12px;
    line-height: 14px;
  }
}
@media screen and (max-width: 1366px) and (max-height: 768px) {
  .SapoFin {
    .feature-item {
      .content {
        .content-detail{
          height: 200px !important;
        }
      }
    }
  }
   .SapoMoneyHeaderContainer{
     .image-los{
       width: 250px;
     }
   }
   .SapoFin {
     .los{
      .slick-slide{
        img{
          width: 100% !important;
        }
      }
     }
   
   }
}
