@import '~antd/dist/antd.css';
@import './bankhub.scss';
@import './global.scss';
@import './fin.scss';
@import './bhConnectCard.scss';
@import './accessControl.scss';
@import './manageAccount.scss';
@import './transferSalary.scss';
@import './transfer.scss';
@import './caculator.scss';

@font-face {
  font-family: 'Helvetica Neue';
  src: url(./../fonts/HelveticaNeue.ttf);
}
